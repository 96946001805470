import Vue from 'vue';
Vue.component('EsignConsent', () => import('@/modules/esign/components/EsignConsent/EsignConsent.vue'));
Vue.component('EsignConsentEditor', () => import('@/modules/esign/components/EsignConsent/EsignConsentEditor.vue'));
Vue.component('CmsMediaHtmlEditorToolbar', () => import('@/modules/cms/components/Media/Html/HtmlEditorToolbar.vue'));

export default {
  "disabled": false,
  "tags": ["esign"],

  "id": "EsignConsent",
  "title": "Consentimiento familiar",
  "icon": "mdi:account-child",

  "block": {
    "component": "EsignConsent",
    "props": {
      "personId": null,
      "documentName": null,
      "source": null
    }
  },

  "editor": {
    "face": {
      "component": "EsignConsentEditor",
      "v-model": "block.props"
    },

    "toolbar": {
      "component": "CmsMediaHtmlEditorToolbar"
    },
  }
};