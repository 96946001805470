<template>
  <div class="filesystem-viewer-grid">
    <div
      class="file-item"
      v-for="file in files"
      :key="file.id"
      :class="{'--selected': isSelected(file)}"
      @click="clickFile(file)"
      :title="file.title"
    >
      <div
        class="file-preview"
        :style="{backgroundImage: file.preview ? `url(${file.preview})` : null}"
      >
        <ui-icon
          v-if="!file.preview"
          class="file-preview-icon"
          size="64"
          :value="`mime:${file.mimetype}`"
        ></ui-icon>
      </div>

      <div class="file-descriptor">
        <h3 class="file-name">{{ file.title }}</h3>
        <p class="file-size">{{ file.size | bytes }}</p>
        <ui-icon
          v-if="$listeners['delete-file']"
          class="file-delete"
          value="g:delete"
          @click.stop="deleteFile(file)"
        ></ui-icon>
      </div>
    </div>

    <slot name="uploader"></slot>

  </div>
</template>

<script>
import { UiIcon } from "@/modules/ui/components";

export default {
  name: "filesystem-viewer-grid",
  components: { UiIcon },

  props: {
    files: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  methods: {
    isSelected(file) {
      return false;
    },

    clickFile(file) {
      this.$emit("click-file", file);
    },

    deleteFile(file) {
      this.$emit("delete-file", file);
    }
  },

  filters: {
    bytes(bytes, precision) {
      if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return "-";
      if (typeof precision === "undefined") precision = 1;
      var units = ["bytes", "kB", "MB", "GB", "TB", "PB"];
      var number = Math.floor(Math.log(bytes) / Math.log(1024));
      return (
        (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +
        " " +
        units[number]
      );
    }
  }
};
</script>

<style lang="scss">
.filesystem-viewer-grid {
  display: flex;
  flex-wrap: wrap;

  & > .file-item {
    cursor: pointer;
    position: relative;
    border: 1px solid transparent;
    border-radius: var(--ui-radius);
    width: 180px;
    margin: 2px;

    &.--selected {
      border: 1px solid var(--ui-color-primary);
    }

    .file-preview {
      height: 86px;
      margin: 8px;

      display: flex;
      align-items: center;
      justify-content: center;

      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 20%;
    }

    .file-descriptor {
      padding: 8px;

      .file-name {
        margin: 0;
        padding: 0;
        font-size: .9em;
        color: rgba(0, 0, 0, 0.75);

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .file-size {
        margin: 0;
        padding: 0;
        font-size: 0.8em;
        color: rgba(0, 0, 0, 0.4);
      }

      .file-delete {
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px;
        color: #fff;
        text-shadow: 0 1px 2px #000;

        &:hover {
          color: var(--ui-color-danger);
        }
      }
    }

    // Hovered file item
    .file-delete {
      opacity: 0;
      transition: opacity var(--ui-duration-snap);
    }

    &:hover {
      border-color: var(--ui-color-primary);

      .file-delete {
        opacity: 1;
      }
    }
  }
}
</style>