import Vue from 'vue';
Vue.component('CmsQuizQuestion', () => import('@/modules/cms/components/Quiz/Question.vue'));
Vue.component('CmsQuizQuestionEditor', () => import('@/modules/cms/components/Quiz/QuestionEditor.vue'));


export default {
  "tags": ["input", "academia"],
  "id": "CmsQuizQuestion",
  "title": "Pregunta",
  "icon": "mdi:format-list-checks",

  "block": {
    "component": "CmsQuizQuestion",
    "v-model": "question",
    "props": {
      "title": "",
      "options": []
    }
  },

  "editor": {
    "face": {
      "component": "CmsQuizQuestionEditor",
      "v-model": "block.props"
    },

    "actions": [
      {
        "title": "Validación",
        "component": "UiFormGenerator",
        "v-model": "block",
        "props": {
          "schema": {
            "fields": [
              {
                "type": "checkbox",
                "label": "Obligatorio",
                "model": "props.required"
              }
            ]
          }
        }
      },

      {
        "title": "Datos",
        "component": "UiFormGenerator",
        "v-model": "block",
        "props": {
          "schema": {
            "fields": [
              {
                "type": "text",
                "label": "Variable",
                "model": "v-model"
              }
            ]
          }
        }
      }
    ]
  }
};