<template>
  <file-manager
    ref="filemanager"
    :path="path"
    @fetched="$emit('fetched', $event)"
  >
    <template #default="{files, isLoading, deleteFile, appendFiles}">
      <ui-loading v-if="isLoading"></ui-loading>
      <viewer-grid
        :files="files"
        @click-file="clickFile"
        @delete-file="deleteFile"
        :selected="files.filter(f => f.url == value)"
      >
        <template #uploader>
          <file-uploader
            class="uploader-item"
            :path="path"
            @success="appendFiles"
          ></file-uploader>
        </template>
      </viewer-grid>
    </template>
  </file-manager>
</template>

<script>
import ViewerGrid from "@/modules/filesystem/components/Viewer/ViewerGrid.vue";
import FileManager from "@/modules/filesystem/components/File/FileManager.vue";
import FileUploader from "@/modules/filesystem/components/File/FileUploader.vue";
import { UiLoading } from "@/modules/ui/components";

export default {
  name: "url-picker",
  components: {
    ViewerGrid,
    FileManager,
    FileUploader,
    UiLoading
  },

  props: {
    path: {
      type: String,
      required: true
    },

    value: {
      type: String,
      required: false,
      default: null
    }
  },

  methods: {
    clickFile(file) {
      this.$emit("input", file.url);
    },

    reload() {
      this.$refs.filemanager.reload();
    }
  },

  filters: {
    bytes(bytes, precision) {
      if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return "-";
      if (typeof precision === "undefined") precision = 1;
      var units = ["bytes", "kB", "MB", "GB", "TB", "PB"];
      var number = Math.floor(Math.log(bytes) / Math.log(1024));
      return (
        (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +
        " " +
        units[number]
      );
    }
  }
};
</script>