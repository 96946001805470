<template>
  <div class="cms-page">
    <div class="cms-page-layout">
      <div
        v-for="(row, rowIndex) in layout"
        :key="rowIndex"
        class="page-layout-row"
      >
        <template v-for="(column) in row.columns">
          <div
            :key="column.id"
            class="page-layout-column"
            :style="{flex: column.flex}"
          >
            <cms-block
              v-for="(block, i) in column.blocks"
              :key="i"
              :value="block"
              :data.sync="pageData"
              @update:data="onUpdateData"
              @page-event="$emit('page-event', $event)"
              @ref:mounted="onRefMounted"
              @ref:destroyed="onRefDestroyed"
            ></cms-block>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {
  validate,
  execute,
  interpolate,
  getProperty,
} from '@/modules/cms/functions';
import useCms from '@/modules/cms/mixins/useCms';
import CmsBlock from '@/modules/cms/components/Block/Block.vue';

export default {
  name: 'cms-page',
  mixins: [useCms],
  components: { CmsBlock },

  props: {
    value: {
      type: Object,
      required: true,
    },

    data: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      layout: [],
      blockRefs: {},
      pageData: {},

      watchers: [],
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.computeLayout();
        this.setupWatchers();
      },
    },

    data: {
      immediate: true,
      // deep: true,
      async handler(newValue) {
        this.pageData = newValue;
      },
    },
  },

  mounted() {
    this.validate();
  },

  methods: {
    setupWatchers() {
      if (!this.value.watch || !Array.isArray(this.value.watch)) {
        return;
      }

      this.value.watch.forEach((watcher, i) => {
        let target = `pageData.${watcher.target}`;

        this.watchers[i] = this.$watch(
          target,
          async function (newValue, oldValue) {
            // console.log('Triggerd watch for', target, newValue, oldValue);
            let actions = Array.isArray(watcher.actions)
              ? watcher.actions
              : [watcher.actions];

            for (let i = 0; i < actions.length; i++) {
              let action = interpolate(
                actions[i],
                Object.assign({}, this.pageData)
              );

              try {
                let result = await execute(action, this);
                if (action['v-model']) {
                  this.setVariable(action['v-model'], result);
                }
              } catch (err) {
                console.warn('Page.vue@watcher', err);
              }
            }
          },
          {
            immediate: watcher.immediate,
            deep: watcher.deep,
          }
        );
      });
    },

    getVariable(propertyName) {
      return getProperty(this.pageData, propertyName);
    },

    setVariable(propertyName, value) {
      let curvar = this.pageData;
      let parts = propertyName.split('.');
      let len = parts.length;

      for (let i = 0; i < len - 1; i++) {
        if (typeof curvar[parts[i]] == 'undefined' || !curvar[parts[i]]) {
          this.$set(curvar, parts[i], {});
        }
        curvar = curvar[parts[i]];
      }

      this.$set(curvar, parts[len - 1], value);
      this.onUpdateData();
    },

    // Validate required blocks
    validate() {
      if (!this.value?.blocks?.length) {
        return;
      }

      const allBlocks = [];
      this.layout.forEach((row) => {
        row.columns.forEach((column) => {
          allBlocks.push(...column.blocks);
        });
      });

      const errors = [];
      allBlocks
        .filter((block) => block['v-model'] && block?.props?.required)
        .forEach((block) => {
          // Ignore invisible blocks
          if (block['v-if'] && !validate(this.pageData, block['v-if'])) {
            return;
          }

          const requiredValue = this.getVariable(block['v-model']);

          const isEmpty =
            typeof requiredValue === 'string'
              ? !requiredValue.trim()
              : Array.isArray(requiredValue)
              ? requiredValue.length == 0
              : !requiredValue;

          if (isEmpty) {
            const errorMessage = block.props.errorMessage
              ? interpolate(block.props.errorMessage, this.pageData)
              : `${block['v-model']} is required`;

            errors.push({
              message: errorMessage,
              variable: block['v-model'],
            });
          }
        });

      this.$emit('update:errors', errors);
    },

    onUpdateData() {
      this.computeLayout();
      this.validate();
      this.$emit('update:data', this.pageData);
    },

    onRefMounted(event) {
      // console.log('Page.vue onRefMounted', event);
      this.blockRefs[event.name] = event.component;
      // this.$set(this.blockRefs, event.name, event.component);
      this.$set(this.data, '$refs', this.blockRefs);
    },

    onRefDestroyed(event) {
      // console.log('Page.vue onRefDestroyed', event);
      this.blockRefs[event.name] = undefined;
      // this.$set(this.blockRefs, event.name, undefined);
      this.$set(this.data, '$refs', this.blockRefs);
    },

    computeLayout() {
      if (!this.value.layout) {
        this.layout = [];
        return;
      }

      let page = JSON.parse(JSON.stringify(this.value));
      let layout = page.layout ? page.layout : [];

      // make sure every layout column has a "blocks" array
      layout.forEach((row) => {
        row.columns.forEach((column) => {
          if (!column.blocks) {
            column.blocks = [];
          }
        });
      });

      // make sure the layout contains every row and column specified in block layout
      page.blocks.forEach((block, blockIndex) => {
        block.layout = Object.assign(
          { row: 0, column: 0, order: blockIndex },
          block.layout
        );

        if (!layout[block.layout.row]) {
          layout[block.layout.row] = { columns: [] };
        }

        if (!layout[block.layout.row].columns[block.layout.column]) {
          layout[block.layout.row].columns[block.layout.column] = {
            flex: 1,
            blocks: [],
          };
        }

        if (!layout[block.layout.row].columns[block.layout.column].blocks) {
          layout[block.layout.row].columns[block.layout.column].blocks = [];
        }

        layout[block.layout.row].columns[block.layout.column].blocks.push(
          block
        );
      });

      layout.forEach((row, rowIndex) => {
        row.columns.forEach((column, columnIndex) => {
          column.id = columnIndex;
          column.blocks.sort((a, b) => a.layout.order - b.layout.order);
        });

        // Ignore invisible columns
        row.columns = row.columns.filter((column, columnIndex) => {
          if (!column['v-if']) {
            return true;
          }
          return validate(this.data, column['v-if']);
        });
      });

      this.layout = layout;
    },
  },
};
</script>

<style lang="scss">
.cms-page {
  .page-layout-row {
    display: flex;
  }
}

@media (max-width: 800px) {
  .cms-page {
    .page-layout-row {
      display: block;
    }
  }
}
</style>