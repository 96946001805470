import Vue from 'vue'

const CMS = new Vue({
  data() {
    return {
      components: {}
    }
  },

  methods: {
    registerComponent(definition) {
      if (!definition || !definition.id) {
        return;
      }
      this.$set(this.components, definition.id, definition);
    },

    getDefinition(block) {
      if (!block || !block.component) {
        return null;
      }

      let definitionId = block.type || block.component;

      if (typeof this.components[definitionId] == 'undefined') {
        return null;
      }

      return this.components[definitionId];
    }
  }
});

// Register all local definitions
import * as allDefinitions from '../blocks'
for (let i in allDefinitions) {
  CMS.registerComponent(allDefinitions[i]);
}

export default {
  computed: {
    $cms() {
      return CMS;
    }
  }
}