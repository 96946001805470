import Vue from 'vue';
import { UiFormGenerator } from '@/modules/ui/components';
Vue.component('UiFormGenerator', UiFormGenerator);
Vue.component('CmsMediaLoremIpsum', () => import('@/modules/cms/components/Media/LoremIpsum/LoremIpsum.vue'));


export default {
  "disabled": true,

  "id": "CmsMediaLoremIpsum",
  "title": "Lorem Ipsum",
  "icon": "g:translate",
  "description": "Lorem ipsum dolor sit amet",

  "block": {
    "component": "CmsMediaLoremIpsum",
    "props": {
      "nParagraphs": 1,
      "nWords": 120
    }
  },

  "editor": {
    "actions": [
      {
        "title": "Editor de Lipsum",
        "component": "UiFormGenerator",
        "v-model": "block",
        "props": {
          "schema": {
            "fields": [
              {
                "type": "number",
                "label": "Cantidad de parrafos",
                "model": "props.nParagraphs"
              },
              {
                "type": "number",
                "label": "Cantidad de palabras",
                "model": "props.nWords"
              }
            ]
          }
        }
      }
    ]
  }
};