import Vue from 'vue';
Vue.component('CmsInput', () => import('@/modules/ui/components/UiInput/UiInput.vue'));
Vue.component('CmsInputEditor', () => import('@/modules/ui/components/UiInputEditor/UiInputEditor.vue'));
Vue.component('CmsInputSettings', () => import('@/modules/cms/components/Input/InputSettings.vue'));

export default {
  "tags": ["input"],

  "id": "CmsInputCheckbox",
  "title": "Checkbox",
  "icon": "mdi:checkbox-blank-outline",

  "block": {
    "component": "CmsInput",
    "v-model": "check",
    "props": {
      "type": "checkbox"
    }
  },

  "editor": {
    "face": {
      "component": "CmsInputEditor",
      "v-model": "block.props"
    },

    "actions": [
      {
        "title": "Checkbox",
        "component": "CmsInputSettings",
        "v-model": "block"
      },

      {
        "title": "Validación",
        "component": "UiFormGenerator",
        "v-model": "block",
        "props": {
          "schema": {
            "fields": [
              {
                "type": "checkbox",
                "label": "Obligatorio",
                "model": "props.required"
              },
              {
                "type": "text",
                "label": "Error message",
                "model": "props.errorMessage"
              }              
            ]
          }
        }
      },

      {
        "title": "Datos",
        "component": "UiFormGenerator",
        "v-model": "block",
        "props": {
          "schema": {
            "fields": [
              {
                "type": "text",
                "label": "Variable",
                "model": "v-model"
              }
            ]
          }
        }
      },

    ]
  }
};