import Vue from 'vue';
// import { UiFormGenerator } from '@/modules/ui/components';
// Vue.component('UiFormGenerator', UiFormGenerator);
Vue.component('CmsMediaVideo', () => import('@/modules/cms/components/Media/Video/Video.vue'));
Vue.component('CmsMediaVideoPicker', () => import('@/modules/cms/components/Media/Video/VideoPicker.vue'));


export default {
  "id": "CmsMediaVideo",
  "title": "Video",
  "icon": "g:slideshow",

  "block": {
    "component": "CmsMediaVideo",
    "props": {
      "url": ""
    }
  },

  "editor": {
    "face": {
      "component": "CmsMediaVideoPicker",
      "v-model": "block.props.url"
    }
    // "actions": [
    //   {
    //     "title": "URL",
    //     "component": "UiFormGenerator",
    //     "v-model": "block",
    //     "props": {
    //       "schema": {
    //         "fields": [
    //           {
    //             "type": "text",
    //             "label": "URL del video",
    //             "model": "props.url"
    //           }
    //         ]
    //       }
    //     }
    //   }
    // ]
  }
};